import React from 'react';
import template from '../../templates/pages/privacy_landing.pug';

class PrivacyLanding extends React.Component {
    render() {
        return template.call(this, {});
    }
}

export default PrivacyLanding;
