import React from 'react';
import template from '../../templates/pages/our_team.pug';

class OurTeam extends React.Component {
    render() {
        return template.call(this, {});
    }
}

export default OurTeam;
