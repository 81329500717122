import React from 'react';

export default function() {
  return (
    <div className="content m-0 p-0 w-100">
      <div className="container-fluid w-100 p-0 mb-2 mb-md-6">
        <div className="row">
          <div className="col-12">
            <div className="card mb-0">
              <div
                className="hero-img-height hero-img-cover-top"
                style={{
                  backgroundImage: 'url(/assets/img/info/ZB_containers_testing.jpg)',
                  filter: 'brightness(70%)'
                }}
                alt="Nurse with clip board talking to patient."
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row m-2 mb-6">
          <div className="title col-12 col-md-6">
            <h2 className="align-center pb-3 mbr-fonts-style display-3">Healthcare and Testing Sites</h2>
            <h3 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
              Support your community in the fight against COVID-19.
            </h3>
          </div>
        </div>
        <div className="row m-2 mb-md-5">
          <div className="col-lg-6 col-12 pr-lg-5">
            <p className="h1 lh-180 mb-3" style={{ fontSize: '30px' }}>
              Zerobase is a free, HIPAA/GDPR-compliant contact tracing platform that helps healthcare providers, local
              officials, and individuals contain and eliminate COVID-19.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              Providers who administer COVID-19 tests can deploy Zerobase with nothing more than paper printouts.
            </p>
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              Because of this, Zerobase can be used at any freestanding, mobile, and temporary testing site.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="media-container-row mt-6">
            <div className="col-lg-12 col-12">
              <div className="media-container-row">
                <div className="media-content">
                  <div className="mbr-section-text">
                    <p className="mbr-text mb-0 mbr-fonts-style" style={{ fontSize: '24px' }}>
                      <strong>Our contact tracing platform monitors</strong> the spread of the virus throughout a
                      community and can provide individualized risk exposure scores, allowing providers to deploy scarce
                      testing and protective resources most efficiently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="media-container-row mt-6">
            <div className="col-lg-12 col-12">
              <div className="media-container-row">
                <div className="media-content">
                  <div className="mbr-section-text">
                    <p className="mbr-text mb-0 mbr-fonts-style" style={{ fontSize: '24px' }}>
                      <strong>Unlike other contact tracing mechanisms</strong> developed so far, Zerobase does not track
                      GPS locations, require downloading an app, or necessitate installing intrusive technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-6">
            <div className="row m-4 p-5 mt-5 border-top border-secondary pb-4 pt-4">
              <div className="col-12" style={{ fontSize: '48px' }}>
                <strong>Here's how it works:</strong>
              </div>
            </div>
            <div className="row align-middle">
              <div className="col-lg-3 col-3 text-center">
                <p style={{ fontSize: '76px' }}>
                  <strong>1</strong>
                </p>
              </div>
              <div className="col-lg-9 col-9 align-middle my-auto">
                <p className="align-middle" style={{ fontSize: '24px' }}>
                  Upon intake, each patient tested for COVID-19 is given a sheet of paper with a Zerobase QR code on it
                  to scan. Each code sheet is unique and anonymous. Batches of code sheets can be printed and used at
                  testing centers or distributed to mobile or temporary sites.
                </p>
              </div>
            </div>
            <div className="row align-middle">
              <div className="col-lg-3 col-3 text-center">
                <p style={{ fontSize: '76px' }}>
                  <strong>2</strong>
                </p>
              </div>
              <div className="col-lg-9 col-9 align-middle my-auto">
                <p className="align-middle" style={{ fontSize: '24px' }}>
                  Using the patient’s trace history, Zerobase’s technology anonymously analyzes community networks of
                  interaction to calculate a risk score for the patient based on their check-in history.
                </p>
              </div>
            </div>
            <div className="row align-middle">
              <div className="col-lg-3 col-3 text-center">
                <p style={{ fontSize: '76px' }}>
                  <strong>3</strong>
                </p>
              </div>
              <div className="col-lg-9 col-9 align-middle my-auto">
                <p className="align-middle" style={{ fontSize: '24px' }}>
                  Tracing and test results will be combined to identify individuals in a community who visited the same
                  places as the patient around the same time.
                </p>
              </div>
            </div>
            <div className="row align-middle">
              <div className="col-lg-3 col-3 text-center">
                <p style={{ fontSize: '76px' }}>
                  <strong>4</strong>
                </p>
              </div>
              <div className="col-lg-9 col-9 align-middle my-auto">
                <p className="align-middle" style={{ fontSize: '24px' }}>
                  Public health officials can then notify individuals who are registered for contact who may have been
                  exposed and advise them to self-quarantine or get tested, even before they exhibit symptoms.
                </p>
              </div>
            </div>
          </div>
          <div className="media-container-row mt-6">
            <div className="col-lg-12 col-12">
              <div className="media-container-row">
                <div className="media-content">
                  <div className="mbr-section-text">
                    <p className="mbr-text mb-0 mbr-fonts-style" style={{ fontSize: '24px' }}>
                      <strong>
                        Our team will work closely with you to ensure a smooth rollout in your facilities.
                      </strong>{' '}
                      Zerobase is designed to support our vital healthcare partners and help them stay safe as they
                      provide the critical emergency care that our communities depend on.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="container">
            <div className="row m-4 p-5 mt-5 border-top border-secondary pb-4 pt-4">
              <div className="col-lg-8 col-12 pr-lg-5 mt-6">
                <p className="h1 lh-180 mb-3" style={{ fontSize: '24px' }}>
                  Support your community in the fight against COVID-19.
                </p>
                <p className="h2 font-weight-normal">Zerobase is free, it’s ready, and it saves lives.</p>
              </div>
              <div className="col-lg-4 col-12 align-middle mt-6">
                <a className="btn btn-primary btn-lg align-middle" href="/healthcare/register" id="register-healthcare">
                  Register your site
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
