import React from 'react';

export default function() {
  return (
    <div className="content">
      <div className="container" style={{ padding: '0' }}>
        <div className="container d-flex align-items-center text-center text-lg-left py-5 py-lg-6 border-bottom">
          <div className="col px-0">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="h1 mb-2">Privacy Policy</h2>
                <h6 className="font-weight-light text-muted">Last updated: 6 April 2020</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row row-grid justify-content-between">
            <div className="col-lg-3">
              <div data-toggle="sticky" data-sticky-offset="50">
                <div className="card">
                  <div className="card-body">
                    <nav className="nav nav-pills flex-column" id="nav-terms">
                      <a className="nav-link text-sm" href="#item-1" data-scroll-href="" data-scroll-to-offset="130">
                        Introduction
                      </a>
                      <a className="nav-link text-sm" href="#item-2" data-scroll-href="" data-scroll-to-offset="130">
                        What data we collect from you
                      </a>
                      <a className="nav-link text-sm" href="#item-3" data-scroll-href="" data-scroll-to-offset="130">
                        How we use your data
                      </a>
                      <a className="nav-link text-sm" href="#item-4" data-scroll-href="" data-scroll-to-offset="130">
                        When we share your data
                      </a>
                      <a className="nav-link text-sm" href="#item-5" data-scroll-href="" data-scroll-to-offset="130">
                        How we store and protect your data
                      </a>
                      <a className="nav-link text-sm" href="#item-6" data-scroll-href="" data-scroll-to-offset="130">
                        Cookies
                      </a>
                      <a className="nav-link text-sm" href="#item-7" data-scroll-href="" data-scroll-to-offset="130">
                        How to contact us
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <br />
              <div className="mb-5">
                <h2 id="item-1">Introduction</h2>
                <p>
                  Zerobase Foundation (“Zerobase”) is committed to protecting your privacy. This privacy policy was
                  created in order to demonstrate Zerobase’s prioritization of your privacy as a user of the Zerobase
                  platform (the “Platform”). This policy explains what information is collected and how this information
                  is used. As our technology evolves and laws change, we may update this notice and any supplemental
                  privacy notices to reflect changes in our practices and applicable law. If we update this policy, we
                  will post a notice of any material updates on our site and revise the “Last Updated” date above.
                </p>
                <h4>Links to Third Party Sites</h4>
                <p>
                  This notice does not apply to any third-party sites that may link to, or be accessible from, our
                  sites. Your interactions with these sites are governed by the third parties’ applicable privacy
                  notices, statements, or policies. We encourage you to read them.
                </p>
              </div>
              <div className="mb-5">
                <h2 id="item-2">What data we collect from you</h2>
                <br />
                <h4>Data we collect automatically</h4>
                <p>
                  We may collect what is called a device “fingerprint,” which tells us about the kind of phone you are
                  using and its browser settings. Such data does not uniquely identify the device, meaning that many
                  devices will have the same fingerprint.
                </p>
                <h4>Data you provide about yourself</h4>
                <p>
                  You may voluntarily provide your email or phone number for the sole purpose of receiving
                  notifications. If you contact us via email, you may give us your email address and any personal data
                  contained in any comments, photos, feedback, or other information you submit. We will not use this
                  information for any purpose except to respond to inquiries.
                </p>
                <h4>Data concerning health</h4>
                <p>
                  When you check in to a certain kind of provider of public health services, we may associate your
                  anonymous ID with a record of the fact that you visited such a provider. With your explicit
                  permission, we may also associate your anonymous ID with an identifier associated with a medical test
                  result.
                </p>
                <h4>Other special categories of data</h4>
                <p>
                  We will not intentionally or unintentionally collect other “special categories of data” under the EU
                  General Data Protection Regulation (“GDPR”) without your explicit consent for one or more specified
                  purposes or as otherwise permitted or required by applicable law. Special categories of data include
                  personal data (a) revealing racial or ethnic origin, political opinions, religious or philosophical
                  beliefs, or trade union membership; or (b) concerning a natural person’s sex life or sexual
                  orientation.
                </p>
                <h4>Minors</h4>
                <p>
                  Our site is not intended for minors (individuals under the age of 13, or equivalent minimum age
                  depending on jurisdiction). We do not knowingly have users that are minors and do not knowingly
                  collect information from minors.
                </p>
              </div>
              <div className="mb-5">
                <h2 id="item-3">How we use your data</h2>
                <br />
                <h4>Purposes</h4>
                <p>
                  The data is being collected for purposes of disease control, epidemic response, and public health
                  research. We may use your personal data to: send you information you have expressly chosen to receive
                  [with your consent], contribute to our archive of information in the public interest [for our
                  legitimate interests]; and/or comply with applicable law, court order, subpoena, or legal process
                  served on us [to comply with legal obligations]. We may use data concerning health because processing
                  is necessary for the purposes of: preventive or occupational medicine; the provision of health or
                  social care or treatment; or the management of health or social care systems and services. We may use
                  your IP address to find out your broad region, such as country or state, to determine whether we need
                  to comply with GDPR or other privacy laws.
                </p>
                <h4>Legal bases under the GDPR</h4>
                <p>
                  If you are in the European Union, we will collect and use your personal data only if we have one or
                  more legal bases for doing so under the GDPR. This means we collect and use your personal data only
                  where: you have given your consent for one or more specific purposes; it is necessary to perform a
                  contract we are about to enter into or have entered into with you; it is necessary for our legitimate
                  interests (or those of a third party) and your interests and fundamental rights do not override those
                  interests; it is necessary to protect the vital interests of you or another natural person; or it is
                  necessary to comply with a legal obligation. We will indicate in brackets the legal basis or bases on
                  which we are relying following each purpose. Where we are relying on consent as the legal basis, we
                  will notify you and seek additional consent before using your personal data for a new purpose that is
                  inconsistent with the original purpose for which we collected it. (However, due to the purposes of
                  Zerobase, this is unlikely.)
                </p>
                <div className="mb-5"></div>
                <h2 id="item-4">When we share your data</h2>
                <br />
                <h4>Relevant authorities in the interest of public health</h4>
                <p>
                  If you voluntarily provided your email or phone number, we may provide your email or phone number to
                  relevant local, state, or federal authorities, as well as healthcare providers, in the interest of
                  public health, for example contacting you to inform you that you may have been exposed to an
                  infectious disease.
                </p>
                <h4>Law enforcement</h4>
                <p>
                  We may share your data with law enforcement, other government agencies or authorities, or third
                  parties as required by applicable law or legal process served on us.
                </p>
              </div>
              <div className="mb-5">
                <h2 id="item-5">How we store and protect your data</h2>
                <br />
                <h4>Storage and transfers</h4>
                <p>
                  By default, we store the anonymous ID that is generated the first time you use the Platform. We store
                  your email or phone number if you choose to provide one of them for purposes of notification.
                </p>
                <h4>Storage period</h4>
                <p>
                  We will store data until it is no longer needed to fulfill the purpose(s) for which it was collected
                  or as otherwise required or permitted by law. At such time, this data will be deleted. If this is not
                  possible, we will securely store your personal data and isolate it from any further use until deletion
                  is possible. We may dispose of any data at our discretion without notice, subject to applicable law.
                </p>
                <h4>Protection</h4>
                <p>
                  As the transmission of data via the internet is not completely secure, we cannot guarantee the
                  security of your information transmitted to our sites and any such transmission is at your own risk.
                  However, we maintain appropriate technical and organizational measures to prevent unauthorized
                  disclosure of, or access to, data. For example, all data transmitted to and stored by our system is
                  encrypted in transit and at rest.
                </p>
                <h4>Deletion</h4>
                <p>
                  If you provided your email or phone number and would like it to be deleted, simply contact us and we
                  will no longer store them.
                </p>
              </div>
              <div className="mb-5">
                <h2 id="item-6">Cookies</h2>
                <br />
                <p>
                  Our site may use cookies, which are small text files stored on your device when you access a website.
                  We use cookies to remember your device’s random ID number to help Zerobase work better.
                </p>
              </div>
              <div className="mb-5">
                <h2 id="item-7">How to contact us</h2>
                <p>
                  You can contact us at info@zerobase.io or our Data Protection Officer (“DPO”) at
                  <a href="mailto:david@zerobase.io"> david@zerobase.io.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
