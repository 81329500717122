import React from 'react';
import template from '../../templates/pages/privacy.pug';

class Privacy extends React.Component {
    render() {
        return template.call(this, {});
    }
}

export default Privacy;
