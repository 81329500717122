import React from 'react';

export default function() {
  return (
    <div className="content p-0">
      <div className="container-fluid w-100 p-0 mb-2 mb-md-6">
        <div className="row">
          <div className="col-12">
            <div className="card mb-0">
              <div
                className="hero-img-height hero-img-cover-top"
                style={{ backgroundImage: 'url(/assets/img/info/ZBHero_volunteer.jpg)', filter: 'brightness(70%)' }}
                alt="Nurse with clip board talking to patient."
              ></div>
              <div className="card-img-overlay text-center d-flex align-items-center">
                <div className="container">
                  <h2 className="text-white mbr-fonts-style display-4 text-center">The Zerobase Foundation</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row m-2 mb-md-5">
          <div className="col-lg-12 col-12">
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              The Zerobase Foundation is an international nonprofit organization of volunteer technologists, business
              leaders, medical professionals, and concerned citizens. Zerobase builds free and open source public health
              technology for the good of communities around the world.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row m-2 vertical-align-middle">
          <div className="title col-12">
            <h3 className="align-center pb-3 mbr-fonts-style display-4 text-center">Our Team</h3>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 order-2 order-md-1">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/aron.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Co-Founder</div>
                    <h3 className="m-0">Aron Szanto</h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="text-muted">
                      Aron leads the Zerobase Foundation team. Aron’s background is in the study of the spread of
                      misinformation through social networks. He holds dual research and engineering appointments at
                      Harvard University and Kensho Technologies, where his work lies at the intersection of machine
                      learning on networks and natural language processing. Aron completed undergraduate and graduate
                      study in applied mathematics at Harvard University.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/john.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Co-Founder</div>
                    <h3 className="m-0">John Lo</h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="text-muted">
                      John leads product engineering at Zerobase. He conceptualized and developed the Zerobase contact
                      tracing method in Cologne to give communities a scalable, privacy-first, virtual public health
                      resource in the face of constrained physical resources. His past projects include tamper-proof
                      hardware, zero knowledge proofs, and public health administration for marginalized communities.
                      John studied statistics and computer science at Harvard University.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/alicia.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Director of Public Information</div>
                    <h3 className="m-0">Alicia McCauley</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/bianca.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Outreach Lead, General Counsel </div>
                    <h3 className="m-0">Bianca Victoria Scott</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/colm.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Product Lead</div>
                    <h3 className="m-0">Colm Byrne</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/david.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Backend Lead</div>
                    <h3 className="m-0">David Harris</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/erik.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Deployments Lead</div>
                    <h3 className="m-0">Erik Sogn</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/gary.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Medical Lead</div>
                    <h3 className="m-0">Gary Chizever, MD</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="row row-sm align-items-center">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-xl rounded"
                      style={{ backgroundImage: 'url(/assets/img/profiles/jason.jpg)' }}
                    ></span>
                  </div>
                  <div className="col">
                    <div className="text-muted">Infrastructure and Security Lead</div>
                    <h3 className="m-0">Jason Spriggs</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-2 mb-md-5 mt-md-5">
          <div className="col-lg-12 col-12">
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              The Zerobase team comprises over 150 volunteers from dozens of countries around the world, including
              technologists, medical professionals, attorneys, political activists, and concerned citizens. No matter
              who or where you are, you can make an immediate impact on our efforts to bring free and accessible public
              health technology to the communities that need it most.{' '}
              <a href="https://tinyurl.com/zerobase-volunteer" target="_blank">
                Join us!
              </a>
            </p>
          </div>
        </div>
        <div className="row m-2 vertical-align-middle">
          <div className="title col-12">
            <h3 className="align-center pb-3 mbr-fonts-style display-4 text-center">Our Partners</h3>
          </div>
        </div>
        <div className="row mt-md-5 align-items-center justify-content-between mb-md-6">
          <div className="col-md-3 col-12 text-center mb-2">
            <img
              className="w-75 pt-2 mt-md-5 mb-md-5 card-img"
              src="/assets/img/partners/hdsi.png"
              alt="Harvard Data Science Initiative"
              style={{ borderRadius: '2%' }}
            />
            <a href="https://datascience.harvard.edu/">
              <p className="forScreen" style={{ fontSize: '20px' }}>
                Harvard Data Science Initiative{' '}
              </p>
            </a>
          </div>
          <div className="col-md-3 col-12 text-center mb-2">
            <img
              className="w-100 pt-2 mt-md-5 mb-md-5 card-img"
              src="/assets/img/partners/max_planck2.png"
              alt="Max Planck Institute"
              style={{ borderRadius: '2%' }}
            />
            <a href="https://www.mpg.de/en">
              <p className="forScreen" style={{ fontSize: '20px' }}>
                Max Planck Institute for Intelligent Systems
              </p>
            </a>
          </div>
          <div className="col-md-3 col-12 text-center mb-2">
            <img
              className="w-75 mb-md-2 card-img"
              src="/assets/img/partners/necsi.png"
              alt="New England Complex Systems Institute"
              style={{ borderRadius: '2%' }}
            />
            <a href="https://necsi.edu/">
              <p className="forScreen" style={{ fontSize: '20px' }}>
                New England Complex Systems Institute
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
