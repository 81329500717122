import React from 'react';

export default function() {
  return (
    <nav className="navbar navbar-expand-sm navbar-light navbar-secondary-blue navbar-primary py-2" id="navbar-primary">
      <div className="container">
        <div className="d-block d-sm-none small">Privacy First Contact Tracing</div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fe fe-menu mr-2"></i>Menu
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="nav nav-tabs navbar-nav">
            <li className="nav-item w-md-100">
              <a className="nav-link" href="/" id="nav-item-home">
                Home
              </a>
            </li>
            <li className="nav-item dropdown w-md-100">
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Use Cases
              </a>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="/individual" id="nav-item-individuals">
                  Individuals
                </a>
                <a className="dropdown-item" href="/community" id="nav-item-community">
                  Community Officials
                </a>
                <a className="dropdown-item" href="/businesses" id="nav-item-business">
                  Business or Public Locations
                </a>
                <a className="dropdown-item" href="/testing" id="nav-item-healthcare">
                  Healthcare Testing Sites
                </a>
              </div>
            </li>
            <li className="nav-item w-md-100">
              <a className="nav-link" href="/about" id="nav-item-about">
                About
              </a>
            </li>
            <li className="nav-item w-md-100">
              <a className="nav-link" href="/privacy" id="nav-item-privacy">
                Privacy
              </a>
            </li>
            <li className="nav-item w-md-100">
              <a className="nav-link" href="/team" id="nav-item-team">
                Team
              </a>
            </li>
            <li className="nav-item w-md-100">
              <a className="nav-link" href="/contact" id="nav-item-contact">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
