import React from 'react';

export default function() {
  return (
    <div className="content pt-0 pb-0 mb-0">
      <div className="container-fluid w-100 p-0 mb-md-6">
        <div className="row">
          <div className="col-12">
            <div className="card mb-0">
              <div
                className="hero-img-height hero-img-cover-center"
                style={{ backgroundImage: 'url(/assets/img/info/ZBHero_privacy.jpg)' }}
                alt="Zerobase logo over network of dots connected with straight lines."
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row m-4 mb-md-6">
          <div className="col-lg-8 col-12 pr-lg-5">
            <h1 className="mb-5" style={{ color: '#1B2935', fontSize: '400%' }}>
              Privacy First
            </h1>
          </div>
        </div>
        <div className="row m-4 mb-md-5">
          <div className="col-lg-6 col-12 pr-lg-5">
            <p className="h1 lh-180 mb-3" style={{ fontSize: '24px' }}>
              Zerobase is designed from the ground up to put your privacy first.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              Unlike other contact tracing mechanisms developed so far, Zerobase does not track GPS locations or require
              downloading an application to your phone.
            </p>
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              Instead, our QR network securely and privately traces the spread of COVID-19 throughout a community
              without tracking your personal identity or your location at all hours of the day.
            </p>
          </div>
        </div>
        <div className="row m-4 mb-5">
          <div className="col-lg-6 col-12 pr-lg-5">
            <p className="h1 lh-180 mb-3" style={{ fontSize: '24px' }}>
              Zerobase was built to help real people.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              The best way for you to stay notified in real time of possible exposure is to securely share your phone
              number. If we don't have access to your phone number, we'll still try to notify you the next time you
              check in to any location, but it will take longer to let you know that you may have been exposed.
            </p>
            <p className="lead lh-180" style={{ fontSize: '24px' }}>
              Any information provided is encrypted and used only to keep you informed.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid w-100 p-0 mb-0" style={{ backgroundColor: '#65C6E1' }}>
        <div className="card shadow-none border-0" style={{ backgroundColor: '#65C6E1' }}>
          <div className="row justify-content-center p-6">
            <div className="col-md-5 col-12 text-left">
              <h2 className="text-white font-weight-normal" style={{ color: 'white', fontSize: '200%' }}>
                <p>We do not work with advertisers.</p>
                <p>We do not sell your information.</p>
                <p>We adhere to industrial standards of data security.</p>
              </h2>
            </div>
            <div className="col-md-5 col-12 text-left">
              <h2>
                <p className="font-weight-normal" style={{ color: '#1B2935', fontSize: '150%' }}>
                  We believe firmly in:
                </p>
                <p className="font-weight-normal" style={{ color: '#1B2935', fontSize: '200%' }}>
                  <strong>“Big Data without Big Brother.”</strong>
                </p>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex align-items-center text-center text-lg-left py-5 py-lg-6">
        <div className="col px-0">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <a
                className="btn btn-secondary btn-lg h2 h1 mb-2"
                href="/privacy-policy"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{ wordWrap: 'break-word', fontSize: '36px' }}
              >
                Privacy Policy
              </a>
              <h6 className="font-weight-light text-muted">Last updated: 6 April 2020</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
