import React from 'react';

export default function() {
  return (
    <div className="content">
      <div className="container" style={{ padding: '0' }}>
        <iframe
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shrUJuPNPVpxebXRB?backgroundColor=yellow"
          frameborder="0"
          width="100%"
          height="1149"
          style={{ background: 'transparent' }}
        ></iframe>
      </div>
    </div>
  );
}
