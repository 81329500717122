import React from 'react';

export default function() {
  return (
    <div className="content">
      <div className="container" style={{ padding: '0' }}>
        <div className="jumbotron text-center">
          <h1>404</h1>
          <p className="lead">Sorry, the page you requested can't be found.</p>
          <a className="btn btn-default" href="/">
            Back to home
          </a>
        </div>
      </div>
    </div>
  );
}
