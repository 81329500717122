import React from 'react';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div
                className="modal modal-blur fade"
                id="modal-privacy-policy"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Privacy Policy</h5>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <svg
                                    className="icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <i>Last updated: 28 March 2020</i>
                            <p>
                                Zerobase Foundation (“Zerobase”) commits
                                completely to protecting your privacy. This
                                privacy policy was created in order to
                                demonstrate Zerobase’s unfaltering
                                prioritization of your privacy as a user of the
                                Zerobase platform (the “Platform). This policy
                                explains what information is collected and how
                                this information is used. As our technology
                                evolves and laws change, we may update this
                                notice and any supplemental privacy notices to
                                reflect changes in our practices and applicable
                                law. If we update this policy, we will post a
                                notice of any material updates on our site.
                            </p>
                            <h4>Links to Third Party Sites</h4>
                            <p>
                                This notice does not apply to any third-party
                                sites that may link to, or be accessible from,
                                our sites. Your interactions with these sites
                                are governed by the third parties’ applicable
                                privacy notices, statements, or policies. We
                                encourage you to read them.
                            </p>
                            <h4>What data we collect from you</h4>
                            <h4>Data we collect automatically</h4>
                            <p>
                                We may collect what is called a device
                                “fingerprint,” which tells us about the kind of
                                phone you are using and its browser settings.
                                Such data does not uniquely identify the device,
                                meaning that many devices will have the same
                                fingerprint.
                            </p>
                            <h4>Data you provide about yourself</h4>
                            <p>
                                You may voluntarily provide your email or phone
                                number for the sole purpose of receiving
                                notifications. If you contact us via email, you
                                may give us your email address and any personal
                                data contained in any comments, photos,
                                feedback, or other information you submit. We
                                will not use this information for any purpose
                                except to respond to inquiries.
                            </p>
                            <h4>Data concerning health</h4>
                            <p>
                                We may collect what is considered data
                                concerning health under the GDPR when you check
                                in to a certain kind of provider of public
                                health services.
                            </p>
                            <h4>Other special categories of data</h4>
                            <p>
                                We will not intentionally or unintentionally
                                collect other “special categories of data” under
                                the EU General Data Protection Regulation
                                (“GDPR”) without your explicit consent for one
                                or more specified purposes or as otherwise
                                permitted or required by applicable law. Special
                                categories of data include personal data (a)
                                revealing racial or ethnic origin, political
                                opinions, religious or philosophical beliefs, or
                                trade union membership; or (b) concerning a
                                natural person’s sex life or sexual orientation.
                            </p>
                            <h4>Minors</h4>
                            <p>
                                Our site is not intended for minors (individuals
                                under the age of 13, or equivalent minimum age
                                depending on jurisdiction), and we do not
                                knowingly have users that are minors.
                            </p>
                            <h4>How we use your data</h4>
                            <h4>Purposes</h4>
                            <p>
                                The data is being collected for purposes of
                                disease control, epidemic response, and public
                                health research. We may use your personal data
                                to: send you information you have expressly
                                chosen to receive [with your consent],
                                contribute to our archive of information in the
                                public interest [for our legitimate interests];
                                and/or comply with applicable law, court order,
                                subpoena, or legal process served on us [to
                                comply with legal obligations]. We may use data
                                concerning health because processing is
                                necessary for the purposes of: preventive or
                                occupational medicine; the provision of health
                                or social care or treatment; or the management
                                of health or social care systems and services.
                                We may use your IP address to find out your
                                broad region, such as country or state, to
                                determine whether we need to comply with GDPR or
                                other privacy laws.
                            </p>
                            <h4>Legal bases under the GDPR</h4>
                            <p>
                                If you are in the European Union, we will
                                collect and use your personal data only if we
                                have one or more legal bases for doing so under
                                the GDPR. This means we collect and use your
                                personal data only where: you have given your
                                consent for one or more specific purposes; it is
                                necessary to perform a contract we are about to
                                enter into or have entered into with you; it is
                                necessary for our legitimate interests (or those
                                of a third party) and your interests and
                                fundamental rights do not override those
                                interests; it is necessary to protect the vital
                                interests of you or another natural person; or
                                it is necessary to comply with a legal
                                obligation. We will indicate in brackets the
                                legal basis or bases on which we are relying
                                following each purpose. Where we are relying on
                                consent as the legal basis, we will notify you
                                and seek additional consent before using your
                                personal data for a new purpose that is
                                inconsistent with the original purpose for which
                                we collected it. (However, due to the purposes
                                of Zerobase, this is unlikely.)
                            </p>
                            <h4>When we share your data</h4>
                            <h4>
                                Relevant authorities in the interest of public
                                health
                            </h4>
                            <p>
                                If you voluntarily provided your email or phone
                                number, we may provide your email or phone
                                number to relevant local, state, or federal
                                authorities, such as healthcare providers, in
                                the interest of public health, for example
                                contacting you to inform you that you may have
                                been exposed to an infectious disease.
                            </p>
                            <h4>Law enforcement</h4>
                            <p>
                                We may share your data with law enforcement,
                                other government agencies or authorities, or
                                third parties as required by applicable law or
                                legal process served on us.
                            </p>
                            <h4>How we store and protect your data</h4>
                            <h4>Storage and transfers </h4>
                            <p>
                                By default, we only store the anonymous ID that
                                is generated the first time you use the
                                Platform. We store your email or phone number if
                                you choose to provide one of them for purposes
                                of notification. We do not store IP addresses.
                                We never store personal health information.
                            </p>
                            <h4>Storage period</h4>
                            <p>
                                We will store data until it is no longer needed
                                to fulfill the purpose(s) for which it was
                                collected or as otherwise required or permitted
                                by law. At such time, this data will be deleted.
                                If this is not possible, we will securely store
                                your personal data and isolate it from any
                                further use until deletion is possible. We may
                                dispose of any data at our discretion without
                                notice, subject to applicable law.
                            </p>
                            <h4>Protection</h4>
                            <p>
                                As the transmission of data via the internet is
                                not completely secure, we cannot guarantee the
                                security of your information transmitted to our
                                sites and any such transmission is at your own
                                risk. However, we maintain appropriate technical
                                and organizational measures to prevent
                                unauthorized disclosure of, or access to, data.
                            </p>
                            <h4>Deletion</h4>
                            <p>
                                If you provided your email or phone number and
                                would like it to be deleted, simply contact us
                                and we will no longer store them.
                            </p>
                            <h4>How to contact us</h4>
                            <p>
                                You can contact us at info@zerobase.io or our
                                Data Protection Officer (“DPO”) at
                                <a href="mailto:security@zerobase.io">
                                    security@zerobase.io.{' '}
                                </a>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary btn-block"
                                type="button"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
        /*return template.call(this, {});*/
    }
}
export default PrivacyPolicy;
